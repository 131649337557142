<template lang="html" src="./creditCard.html" />

<script type="text/javascript" src="https://js.iugu.com/v2"></script>
<script>
import mixins from "@/mixins/mixins"
import WholeMultiselect from "@/components/whole-multiselect/WholeMultiselect.vue"

export default {
  mixins: [mixins],
  components: { WholeMultiselect },
  props:{
    subscriptionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Iugu: null,
      windowWidth: 0,
      webScreen: true,
      showWebVersion: false,
      showMobileVersion: false,
      mobileScreen: false,
      clickedAddonsId: null,
      totalShoppingCart: 0,
      dismissSecs: 5,
      payment: "",
      isDisable: false,
      isPlan: false,
      dismissCountDown: 0,
      planId: null,
      customer: null,
      flag: null,
      busy: false,
      cardholderName: "",
      cpf: null,
      cardNumber: "",
      expiration: "",
      cvc: "",
      tokenCard: null,
      invalidField: {
        cardholderName: false,
        cpf: false,
        cardNumber: false,
        expiration: false,
        cvc: false,
      },
      alertMessage: {
        cardholderName: "",
        cpf: "",
        cardNumber: "",
        expiration: "",
        cvc: "",
      },
      creditCardInfo: {},
      cards: [
        {
          type: "visa",
          bin: /^4/,
          format: /(\d{1,4})/g,
          length: [13, 16],
          cvcLength: [3],
        },
        {
          type: "mastercard",
          bin: /^(5[1-5]|2[2-7])/,
          format: /(\d{1,4})/g,
          length: [16],
          cvcLength: [3],
        },
        {
          type: "amex",
          bin: /^3[47]/,
          format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
          length: [15],
          cvcLength: [3, 4],
        },
        {
          type: "diners",
          bin: /^3[0689]/,
          format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
          length: [14],
          cvcLength: [3],
        },
        {
          type: "discover",
          bin: /^6([045]|22)/,
          length: [16],
          cvcLength: [3],
        },
        {
          type: "elo",
          format: /(\d{1,4})/g,
          bin: /^4011(78|79)|43(1274|8935)|45(1416|76)|50(4175|6699|67|9[0-9])|63(6297|6368)|6500([3-5])|6504([0-3][0-9])|650(4[8-9]|5)|6507[0-2][0-9]|6509([0-2][0-9])|6516[5-7][0-9]|6550[0-5][0-9]/y,
          length: [16],
          cvcLength: [3],
        },
      ],
    }
  },
  watch: {
    cardNumber: function (newVal) {
      if (!this.testNullUndefinedEmpty(newVal)) {
        for (const card in this.cards) {
          if (this.cards[card].bin.test(newVal)) {
            this.flag = this.cards[card].type
          }
        }
      } else {
        this.flag = null
      }
    },
  },
  created: function () {
    this.createIuguScript()
    this.handleResize()

    window.addEventListener("resize", this.handleResize)
  },
  destroyed: function () {
    window.removeEventListener("resize", this.handleResize)
  },
  mounted: function () {
    this.getCustomerData()
    const plan = JSON.parse(localStorage.getItem("plan"))
  },
  methods: {
    hello (data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this.creditCardInfo[key] = data[key]          
        }
      }
      this.$emit('input-payment', this.creditCardInfo)
    },
    createIuguScript() {
      this.Iugu = document.createElement("script")
      this.Iugu.setAttribute("src", "https://js.iugu.com/v2")

      document.head.appendChild(this.Iugu)

      setTimeout(() => {
        this.initIugu()
      }, 3000)
    },
    reset() {
      this.cardholderName = ""
      this.cpf = ""
      this.cardNumber = ""
      this.expiration = ""
      this.cvc = ""
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    getCustomerData() {
      this.customer = this.$session.get("customerData")
      this.clickedAddonsId = this.subscriptionData.clickedAddonsId
      this.totalShoppingCart = this.subscriptionData.totalOnlyNewSelectedAddons

      this.isPlan = this.subscriptionData.isPlan
      this.planId = this.subscriptionData.plan.id
      this.cpf = !this.testNullUndefinedEmpty(this.customer)
        ? this.maskCpf(this.customer.cpf)
        : null
    },
    handleResize() {
      this.windowWidth = document.documentElement.clientWidth

      this.webScreen = !this.windowWidth < 788
      this.mobileScreen = this.windowWidth < 788
    },
    validateFields(){
      let allValid = true
      this.busy = true
      const notFillAlertMsg = "Campo obrigatório"
      const invalidNumberCard = "Insira um cartão válido"

      if (this.testNullUndefinedEmpty(this.cardholderName)) {
        this.invalidField.cardholderName = true
        this.alertMessage.cardholderName = notFillAlertMsg
        allValid = false

      } else if (!this.testValidName(this.cardholderName)) {
        this.invalidField.cardholderName = true
        this.alertMessage.cardholderName = "Insira o nome como consta no cartão"
        allValid = false
      }

      if (this.testNullUndefinedEmpty(this.cpf)) {
        this.invalidField.cpf = true
        this.alertMessage.cpf = notFillAlertMsg
        allValid = false
      } else if (!this.validateCPF(this.cpf)) {
        this.invalidField.cpf = true
        this.alertMessage.cpf = "Insira um CPF válido"
        allValid = false
      }
      if (this.testNullUndefinedEmpty(this.cardNumber)) {
        this.invalidField.cardNumber = true
        this.alertMessage.cardNumber = invalidNumberCard
        allValid = false
      }
      else if (this.cardNumber.length < 13) {
        console.log(this.cardNumber.length)
        this.invalidField.cardNumber = true
        this.alertMessage.cardNumber = invalidNumberCard
        allValid = false
      }
      else if (this.testNullUndefinedEmpty(this.flag)) {
        this.invalidField.cardNumber = true
        this.alertMessage.cardNumber = invalidNumberCard
      }
      if (this.testNullUndefinedEmpty(this.expiration)) {
        this.invalidField.expiration = true
        this.alertMessage.expiration = notFillAlertMsg
        allValid = false

      } else if (this.expiration.length < 3) {
        this.invalidField.expiration = true
        this.alertMessage.expiration = "Insira uma data válida"
        allValid = false
      }
      if (this.testNullUndefinedEmpty(this.cvc)) {
        this.invalidField.cvc = true
        this.alertMessage.cvc = notFillAlertMsg
        allValid = false

      } else if (this.cvc.length < 3) {
        this.invalidField.cvc = true
        this.alertMessage.cvc = "Insira um código válido"
        allValid = false

      }
      if(allValid){
        this.$bvModal.show("modal-upgrade-confirmation")
      } else {
        this.setBusyFalse()
      }
    },
    setBusyFalse(){
      setTimeout(() => {
        this.busy = false
      }, 300);
    },
    initIugu() {
      Iugu.setAccountID(this.$store.state.iuguNotifyAccountId)
      Iugu.setup()
      Iugu.setTestMode(this.$store.state.iuguTestMode)

      console.log({
        iuguNotifyAccountId: this.$store.state.iuguNotifyAccountId,
        iuguTestMode: this.$store.state.iuguTestMode,
        Iugu
      })
    },
    payWithCreditCard() {
      this.$vs.loading()

      this.$gtm.trackEvent({
        // click_btn_pc_carrinhoupgrade_comprar
        event: "plan_upgrade_kart_buy_btn_click",
        category: "Pagamento Upgrade",
        action: "plan_upgrade_kart_buy_btn_click",
        params: {
          paymentMethod: "credit_card",
          services: !this.testNullUndefinedEmpty(this.subscriptionData.addons)
            ? this.subscriptionData.addons.filter(a => a.checked == true)
            : null,
          value: this.totalShoppingCart,
          planId: this.planId,
        },
        label: "Na página  pagamento do upgrade de plano - Clicar no botão ‘Finalizar’",
      })

      this.isDisable = true
      this.$bvModal.hide("modal-upgrade-confirmation")
      const cardOnlyNumber = this.cardNumber.replace(/[^\d]+/g, "")
      const paymentInfo = {
        upload_register_id: null,
        description: "TAX_NEW_CARD",
        email: this.customer.email,
        cpf: this.cpf.replace(/\D+/g, ""),
        method: "credit_card",
        b2b: false,
      }
      const nameArray = this.cardholderName.split(" ")
      const firstName = nameArray[0]
      nameArray.splice(0, 1)
      const lastName = nameArray.join(" ")
      setTimeout(() => {
        this.busy = true
      }, 300)
      this.initIugu()
      Iugu.CreditCard()

      const expirationArray = this.expiration.split("/")
      const expirationMonth = expirationArray[0]
      const expirationYear = expirationArray[1]

      const iuguCreditCard = Iugu.CreditCard(
        cardOnlyNumber,
        expirationMonth,
        expirationYear,
        firstName,
        lastName,
        this.cvc
      )

      console.log({ CreditCard: iuguCreditCard, valid: iuguCreditCard.valid() })

      if (iuguCreditCard.valid()) {
        console.log("CARTAO DE CREDITO VALIDO")
        Iugu.createPaymentToken(iuguCreditCard.toData(), (response) => {
          console.log({"Response IUGU": response})
          if (response && response.id) {
            paymentInfo.token = response.id
            this.tokenCard = response.id
            this.createCustomerRecurrence()
          } else {
            console.log("Erro ao criar token IUGU")
            this.showNotifyError(
              "Erro ao validar cartão, não foi possível criar o token na Iugu",
              6000
            )
            this.setBusyFalse()
            this.$vs.loading.close()
            this.isDisable = false
          }
        })
      } else {
        this.invalidField.cardNumber = true
        this.invalidField.expiration = true
        this.invalidField.cvc = true
        const textAlert = `Transação não autorizada. Cartão inválido, não foi
          possível processar a transação. Reveja os dados informados e tente
          novamente. Se o erro persistir, entre em contato com seu banco emissor.`
        this.showNotifyWarning(textAlert, 6000)
        this.isDisable = false
        this.setBusyFalse()
        this.$vs.loading.close()
      }
    },
    createCustomerRecurrence() {
      const url = !this.isPlan
        ? "patient/upgrade-services-plan"
        : "patient/patient-upgrade-plan"

      const dataCustomer = {
        patientId: this.customer.patientId,
        services: !this.testNullUndefinedEmpty(this.clickedAddonsId)
          ? this.clickedAddonsId
          : null,
        chargeRequest: {
          method: "credit_card",
          token: this.tokenCard,
          amount: this.totalShoppingCart,
          email: this.customer.email,
        },
      }

      if(this.isPlan) {
        dataCustomer.customerPlanId = this.planId
      }

      this.axios.post(this.$store.state.filooServer+url, dataCustomer)
        .then(async ({ status }) => {
          this.$vs.loading.close()
          this.isDisable = status === 200

          if (this.isDisable) {
            this.showSuccessAlert()
            this.payment = "disable-button"
            localStorage.removeItem("planAndAddOnsData")
            this.setBusyFalse()
            
            await this.getMe()
            this.$router.push("/plan-upgrade")            
          } else {
            const textAlert = "Transação negada pela operadora do cartão, gostaria de trocar o cartão ou utilizar outro método de pagamento?"
            this.showNotifyWarning(textAlert, 4000)
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.showNotifyError(error.response.data.message || "Não foi possível gerar pagamento no cartão", 6500)

          console.error({
            method: "createCustomerRecurrence",
            error: error,
            endpoint: url,
            "http-method": "POST",
            "sent-object": dataCustomer,
          })

          $router.push({ name: 'plan-upgrade'})
          this.$bvModal.show("modal-error-upgrade")
          this.setBusyFalse()
          this.isDisable = false
        })
    },
  },
}
</script>

<style lang="scss" src="./creditCard.scss" />
