<template lang="html" src="./shoppingCart.html"/>

<script>
import mixins from "@/mixins/mixins";
import paymentMethods from "./paymentMethods/paymentMethods.vue"

export default {
  mixins: [mixins],
  components:{
    "payment-methods": paymentMethods,
  },
  props:{
    planAndAddOnsData: {
      type: Object,
    },
  },
  data() {
    return {
      selectedAddons: [],
      total: 0,
      show: false,
      plan: null,
      windowWidth: 0,
      webScreen: true,
      mobileScreen: false,
      subscriptionData: null,
    }
  },
  created: function () {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  destroyed: function () {
    window.removeEventListener("resize", this.handleResize)
  },
  mounted() {
    if(!this.$props.planAndAddOnsData.isPlan){
      this.fillAddonsAndTotal()
    } else{
      this.show = true
      this.fillPlansTotal()
    }
  },
  methods: {
    fillAddonsAndTotal(){
      const vm = this;
      vm.subscriptionData =  JSON.parse(localStorage.getItem("planAndAddOnsData"));
      if(vm.testNullUndefinedEmpty(vm.$props.planAndAddOnsData)){
        vm.plan = vm.subscriptionData.plan;
        vm.total = vm.subscriptionData.totalOnlyNewSelectedAddons;
        vm.selectedAddons = vm.subscriptionData.addons.filter(a => a.checked == true);
      } else {
        vm.plan = vm.$props.planAndAddOnsData.plan;
        vm.total = vm.$props.planAndAddOnsData.totalOnlyNewSelectedAddons;
        vm.selectedAddons = vm.$props.planAndAddOnsData.addons.filter(a => a.checked == true);
      }     
    },
    fillPlansTotal(){
      this.subscriptionData = JSON.parse(localStorage.getItem("planAndAddOnsData"))

      if(this.planAndAddOnsData) {
        this.plan = this.$props.planAndAddOnsData.plan;
        this.total = this.planAndAddOnsData.totalOnlyNewSelectedAddons
      } else {
        this.plan = this.subscriptionData.plan
        this.total = this.subscriptionData.totalOnlyNewSelectedAddons
      }    
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = document.documentElement.clientWidth;
      if (vm.windowWidth < 788) {
        vm.webScreen = false;
        vm.mobileScreen = true;
      } else {
        vm.webScreen = true;
        vm.mobileScreen = false;
      }
    },
  },
}
</script>

<style lang="scss" src="./shoppingCart.scss"/>
