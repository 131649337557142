<template lang="html" src="./paymentMethods.html"/>

<script>
import creditCard  from "./creditCard/creditCard.vue";
import pix from "./pix/pix.vue";
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],
  components: {
    "credit-card": creditCard,
    pix,
  },
  props:{
    subscriptionData: {
      type: Object,
    },
    handlePaymentInformationInput: {
      type: Function,
      default: () => {},
    },
  },
  data () {
    return {
      customer: {},
      activePaymentMethod: 2,
      showMessageNextStep: false,
      showMessageAlert: true,
    }
  },
  setup () {
    return {
      paymentMethods: [
        {
          id: 2,
          name: "credit-card",
          media: require("@/assets/svgs/myPlan/planUpgrade/shoppingCart/paymentMethods/credit-card.svg"),
          allowed: true,
          alt: "Ícone Cartão de crédito",
        },
        {
          id: 3,
          name: "pix",
          media: require("@/assets/svgs/myPlan/planUpgrade/shoppingCart/paymentMethods/pix.svg"),
          allowed: true,
          alt: "Ícone Pix",
        },
      ],
    }
  },
  computed: {
    alertVariant () {
      if (this.showMessageAlert) {
        return "warning"
      }

      if (this.showMessageNextStep) {
        return "success"
      }
    },
  },
  mounted () {
    this.customer = this.$session.get("customerData")
  },
  methods: {
    setActivePayment (id) {
      window.scrollTo(0, 0)

      if (id !== this.activePaymentMethod) {
        this.showMessageNextStep = false
        this.activePaymentMethod = id
        this.showMessageAlert = true
      }
    },
    setNextStep () {
      this.showMessageAlert = false

      if (this.customer && this.customer.customerPlan && this.customer.customerPlan.code) {
        this.showMessageNextStep = this.customer.customerPlan.code === "freemium"
          ? 2
          : 1
      } else {
        this.showMessageNextStep = false
      }
    },
  },
}
</script>

<style lang="scss" src="./paymentMethods.scss"/>
